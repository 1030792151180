import { Box, alpha, styled } from "@mui/material";

export const HeaderSection = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: "100%",
  left: "0",
  top: "30px",
  zIndex: "10",
  backgroundColor: alpha(theme.colors.white, 0.9),
  boxShadow: "5px 7px 15px rgba(40, 54, 118, 0.0)",
  transition: "0.3s all ease",
  backdropFilter: `blur(10px)`,
  [theme.breakpoints.down("lg")]: {
    padding: `10px 0px`,
  },
  "&.header-fixed": {
    top: "0px",
    boxShadow: "5px 7px 15px rgba(40, 54, 118, 0.1)",
    transition: "0.3s all ease",
  },
  "&.nav-down": {
    transform: "translateY(0)",
  },
  "&.nav-up": {
    transform: "translateY(-100%)",
  },
  ".header-box": {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 0px",
    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap",
    },
    ".header-logo": {
      display: "flex",
      svg: {
        width: "150px",
        height: "auto",
      },
    },
    ".nav-box": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      padding: "0",
      [theme.breakpoints.down("lg")]: {
        flexWrap: "wrap",
        width: "100%",
        marginTop: "15px",
      },
      li: {
        padding: "0",
        marginLeft: "30px",
        display: "inline-block",
        width: "auto",
        [theme.breakpoints.down("lg")]: {
          display: "block",
          width: "100%",
          marginLeft: "0",
        },
        "&:last-child": {
          ".nav-link": {
            [theme.breakpoints.down("lg")]: {
              marginBottom: "0px",
              borderBottom: `0px solid #dddddd`,
            },
          },
        },
      },
      ".nav-link": {
        backgroundColor: "transparent",
        color: `${theme.colors.black} !important`,
        fontSize: "16px",
        fontWeight: "500",
        display: "flex",
        alignItems: "center",
        padding: "25px 0px",
        ".MuiTouchRipple-child": {
          backgroundColor: "transparent",
        },
        [theme.breakpoints.down("lg")]: {
          width: "100%",
          justifyContent: "space-between",
          padding: "10px 0px",
          marginBottom: "10px",
          borderRadius: "0",
          borderBottom: `1px solid #dddddd`,
        },
        svg: {
          fontSize: "24px",
          marginLeft: "5px",
        },
      },
      ".sub-nav-link": {
        backgroundColor: theme.colors.black,
      },
    },
    ".aussizzgroup-logo": {
      width: "auto",
      height: "85px",
      padding: `10px 0px`,
      marginLeft: "10px",
      [theme.breakpoints.down("lg")]: {
        padding: `0px 0px`,
      },
      [theme.breakpoints.down("md")]: {
        height: "50px",
      },
    },
  },
  ".responsive-btn": {
    padding: "10px 15px",
    minWidth: "auto",
    [theme.breakpoints.down("lg")]: {
      padding: "10px 12px",
    },
    svg: {
      path: {
        fill: theme.colors.white,
      },
    },
  },
}));

export const MobileMenu = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  ".res-menu-box": {
    padding: "0",
    li: {
      ".sub-nav-link": {
        backgroundColor: "transparent !important",
        color: `#000000 !important`,
        fontWeight: "500",
        padding: "7px 0px",
        display: "block",
        fontSize: "16px",
        lineHeight: "18px",
        marginBottom: "9px",
      },
    },
  },
}));
