import dynamic from "next/dynamic";
const Header = dynamic(() => import("common/Layout/Header/Header"), {
  ssr: true,
});
const Footer = dynamic(() => import("common/Layout/Footer/Footer"), {
  ssr: true,
});
import { LayoutOptions } from "next/app";
import Head from "next/head";
import { MainSection } from "./Layout.styled";
// import { BreadcrumbSectionBox } from "common/Components/BreadcrumbSection/BreadcrumbSection.styled";
const BreadcrumbSection = dynamic(() => import("common/Components/BreadcrumbSection/BreadcrumbSection"), {
  ssr: true,
});
import logo from "../../../public/assets/images/logo.webp"
import { useRouter } from "next/router";
interface LayoutProps {
  children: React.ReactNode;
  layoutOptions: LayoutOptions;
}

export const Layout = ({ layoutOptions, children }: LayoutProps) => {
  const router = useRouter();
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={logo.src} sizes="32x32" />
        <link rel="icon" href={logo.src} sizes="192x192" />
        <link rel="apple-touch-icon" href={logo.src} />
        <title>{layoutOptions.pageTitle || "Aussizz Group"}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="keywords" content={layoutOptions.pageKeywords || ""} />
        <meta
          name="description"
          content={layoutOptions.pageDescription || ""}
        />
        {process.env.NEXT_PUBLIC_IS_PROD === 'false' ? <meta name="robots" content="noindex,nofollow"></meta> : <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"></meta>}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={layoutOptions.pageTitle || "Aussizz Group"}
        />
        <meta
          property="og:description"
          content={layoutOptions.pageDescription || ""}
        />
        <meta property="og:url" content="https://www.pyaustralia.com.au/" />
        <meta property="og:site_name" content="Professional Year Program" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/aussizz"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@aussizz" />
        <meta
          name="og:image"
          content={layoutOptions.imageUrl || logo.src}
        />
      </Head>
      <Header />
      {router.asPath != '/' && <BreadcrumbSection />}
      <MainSection>{children}</MainSection>
      {router.isReady && <Footer />}
    </>
  );
};
