import { alpha, Box, styled, Typography } from "@mui/material";
import banner from "../../../../public/assets/images/banner-bg.webp"
export const FooterSection = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: "80px 0px 0px",
  backgroundColor: "#F2FBFE",
  overflow: "hidden",
  backgroundImage: `url(${banner.src})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  [theme.breakpoints.down("xl")]: {
    padding: "70px 0px 0px",
  },
  [theme.breakpoints.down("xs")]: {
    padding: "40px 0px 0px",
  },
  ".footer-effect": {
    position: "absolute",
    bottom: "-35px",
    right: "0",
    opacity: "0.2",
    [theme.breakpoints.down("md")]: {
      opacity: "0.1",
    },
    svg: {
      width: "400px",
      height: "auto",
    },
  },
}));

export const FooterPanel = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: "1",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
  ".footer-logo": {
    svg: {
      width: "180px",
      height: "auto",
    },
  },
  ".social-list": {
    padding: "0",
    marginTop: "30px",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    ".s-item": {
      padding: "0",
      display: "inline-block",
      width: "auto",
      marginRight: "15px",
    },
    ".social-media-link": {
      width: "40px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.colors.white,
      borderRadius: "10px",
      boxShadow: `0px 2px 10px 0px ${alpha(theme.colors.black, 0.02)}`,
      svg: {
        fontSize: "22px",
        path: {
          fill: theme.colors.primaryColor,
        },
      },
      "&:hover": {
        backgroundColor: theme.colors.primaryColor,
        svg: {
          fontSize: "22px",
          path: {
            fill: theme.colors.white,
          },
        },
      },
    },
  },
  ".footer-menu": {
    padding: "0",
    ".footer-navitem": {
      padding: "0",
      marginBottom: "12px",
      ".nav-item-link": {
        fontSize: "16px",
        color: "#555555 !important",
        fontWeight: "500",
        "&:hover": {
          color: `${theme.colors.primaryColor} !important`,
        },
      },
    },
    ".address-navitem": {
      alignItems: "flex-start",
      marginBottom: "15px",
      svg: {
        fontSize: "24px",
      },
      ".address-text": {
        color: "#555555",
        fontWeight: "500",
        fontSize: "16px",
        marginLeft: "10px",
        marginTop: "1px",
      },
    },
  },
  ".app-box": {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    ".app-btn": {
      padding: "10px 10px",
      svg: {
        width: "150px",
        height: "40px",
      },
      "&:first-child": {
        marginRight: "10px",
      },
      "&:last-child": {
        marginLeft: "10px",
      },
    },
  },
}));

export const FooterTitle = styled(Typography)(({ theme }) => ({
  position: "relative",
  fontSize: "18px",
  color: theme.colors.black,
  fontWeight: "700",
  marginBottom: "30px",
  [theme.breakpoints.down("xl")]: {
    marginBottom: "20px",
  },
}));

export const CopyWriteSection = styled(Box)(({ theme }) => ({
  position: "relative",
  paddingTop: "20px",
  borderTop: `1px solid ${theme.colors.borderColor}`,
  paddingBottom: "20px",
  marginTop: "50px",
  zIndex: "1",
  [theme.breakpoints.down("xl")]: {
    marginTop: "30px",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "30px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  ".cw-text": {
    fontSize: "16px",
    lineHeight: "20px",
    color: theme.colors.textColor,
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
}));
