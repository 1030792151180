import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Logo } from "ui/shared/logo";
import {
  CopyWriteSection,
  FooterPanel,
  FooterSection,
  FooterTitle,
} from "./Footer.styled";
import {
  AddressIcon,
  ArrowRightOutlineIcon,
  FacebookIcon,
  InstaSocialIcon,
  LinkedSocialIcon,
  MailOutlineIcon,
  PhoneIcon,
  TwitterIcon,
} from "ui/icons";
import Link from "next/link";
import dynamic from "next/dynamic";
import React from "react";
import { CCL_URL, GMP_URL, IELTS_URL, OS_URL, PTE_URL } from "helpers/constant";
const EnrollDialogForm = dynamic(
  () => import("common/Components/EnrollDialogForm/EnrollDialogForm"),
  {
    ssr: true,
  }
);

const Footer = () => {
  const [open, setOpen] = React.useState(false);

  const handleEnrollFormOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FooterSection>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item lg={2.5} md={4} sm={6} xs={12}>
            <FooterPanel>
              <Link
                prefetch={false}
                passHref
                className="footer-logo"
                href="/"
                title="Professional Year Program | IT, Accounting & Engineering | Australia | Aussizz Group"
              >
                <Logo />
              </Link>
              <List className="social-list">
                <ListItem className="s-item">
                  <Link
                    prefetch={false}
                    passHref
                    className="social-media-link"
                    href="https://www.facebook.com/aussizz"
                    title="Find us on Facebook | Aussizz Group"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </Link>
                </ListItem>
                <ListItem className="s-item">
                  <Link
                    prefetch={false}
                    passHref
                    className="social-media-link"
                    href="https://twitter.com/aussizz"
                    title="Find us on Twitter | Aussizz Group"
                    target="_blank"
                  >
                    <TwitterIcon />
                  </Link>
                </ListItem>
                <ListItem className="s-item">
                  <Link
                    prefetch={false}
                    passHref
                    className="social-media-link"
                    href="https://www.instagram.com/aussizz.group/"
                    title="Find us on Instagram | Aussizz Group"
                    target="_blank"
                  >
                    <InstaSocialIcon />
                  </Link>
                </ListItem>
                <ListItem className="s-item">
                  <Link
                    prefetch={false}
                    passHref
                    className="social-media-link"
                    href="https://www.linkedin.com/company/aussizzgroup"
                    title="Find us on Linkedin | Aussizz Group"
                    target="_blank"
                  >
                    <LinkedSocialIcon />
                  </Link>
                </ListItem>
              </List>
              <Button id="footer-enquire-now" onClick={handleEnrollFormOpen}>
                Enquire Now  <ArrowRightOutlineIcon />
              </Button>
              <Typography mt={1}>
            <Button className="mt-3" style={{ color: 'white !important' }}
                href="https://aussizz.atlassian.net/servicedesk/customer/portal/9" target="_blank" rel="noopener noreferrer">
                <span style={{ color: 'white' }}>Get Support&nbsp;</span>
                <svg style={{ width: '27px' }} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    viewBox="0 0 320 320">
                    <g>
                        <g>
                            <g>
                                <path fill="#ffffff"
                                    d="M120,10.4C105.9,12,95.8,15,84.1,20.7c-32.4,16-55.6,49.1-59.3,84.7c-0.5,4-1.1,7.2-1.8,8.5c-1.1,2.1-1.1,3.9-1.1,40c0,41.1-0.1,40,3.3,43.2c2.3,2.1,4.4,2.8,8.5,2.8h3.5l0.4,3.4c2,16.7,12.8,28.4,31.8,34.7c6.9,2.2,17.1,3.8,26.8,4.2c8,0.3,8.8,0.5,12.1,2.1l3.6,1.8l17.6-0.2c20.1-0.2,20.8-0.4,24.2-5.1c1.7-2.4,1.8-2.9,1.9-8.8c0.2-5.7,0.1-6.6-1.2-8.9c-0.8-1.5-2.4-3.5-3.6-4.4l-2.3-1.8l-17.3-0.2l-17.3-0.2v4.5v4.4h15.1c9.3,0,15.5,0.2,16.2,0.6c0.9,0.5,1.1,1.3,1.1,4.9c0,2.6-0.3,4.6-0.7,5c-0.5,0.5-5.1,0.7-16.8,0.7h-16.2l-0.9-1.7l-1-1.7l-12.1-0.4c-12.7-0.4-19.4-1.3-27.2-4c-14.1-4.7-22.9-14-24.5-25.8l-0.4-3.1h10.9c11.6,0,13.2-0.3,16-2.8c3.4-3.2,3.3-2.1,3.3-43.2c0-41.1,0.1-39.3-3.4-42.5c-2.8-2.7-3.8-2.9-12.2-2.9c-6,0-8-0.2-8-0.8c0.1-2.1,2.5-12,4.1-16.6c9.4-26.5,32.6-46.7,59.2-51.3c6.6-1.1,17.6-1.1,24,0c14.3,2.5,27.5,9.3,38.6,19.9c8.8,8.4,15.1,17.6,19.4,28.4c2.2,5.4,5,16,5,18.8v1.7h-8.3c-7.5,0-8.6,0.1-10.7,1.3c-1.3,0.8-3,2.4-3.8,3.8l-1.4,2.4v37.9c0,30.8,0.2,38.2,0.8,39.8c0.9,2.3,3.6,4.8,5.8,5.7c2.2,0.9,39.3,0.9,41.5,0c2.2-0.8,4.9-3.4,5.8-5.7c0.7-1.5,0.8-8.8,0.8-39.3c0-22.8-0.2-38-0.6-39.1c-0.3-0.9-0.9-5.2-1.5-9.4c-4.7-40.3-31-74.8-68.2-89.1C150.6,11.6,132.4,9,120,10.4z M140.7,20.3c20.2,3.1,37,11.4,52.1,26c14.3,13.7,24.2,31.3,28.4,50.2c2.8,13,3.1,18.3,3.4,57.3l0.2,36.7h-18h-18v-36.3V118h12.4h12.4v-3.6c0-5.4-1.6-15.1-3.6-21.9c-2.3-8-8.9-21.2-13.9-27.8c-13.8-18.5-33.6-30.7-55.6-34.3c-6.9-1.1-21.1-0.8-27.7,0.6C96,34.6,82.4,42,69.8,54.6c-16,16-24.6,34.9-26.5,58.5l-0.4,4.9h12.2h12.1v36.3v36.3h-18h-18v-36.1c0-32.1,0.1-36.2,0.9-37.1c0.6-0.7,1.1-3,1.5-7.4c1-12.4,4.2-24,9.7-35.3C61.7,36.9,101.9,14.5,140.7,20.3z" />
                            </g>
                        </g>
                    </g>
                </svg>
            </Button>
            </Typography>
            </FooterPanel>
          </Grid>
          <Grid item lg={1.5} md={4} sm={6} xs={12}>
            <FooterPanel>
              <FooterTitle>Programs</FooterTitle>
              <List className="footer-menu">
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/it-program"
                    title="IT (Information Technology) - Professional Year Program | Australia | Aussizz Group"
                  >
                    IT Professional
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/engineering-program"
                    title="Engineering Professional - Professional Year Program | Australia | Aussizz Group"
                  >
                    Engineering
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/accounting-program"
                    title="Accounting Professional Year (PY) - Professional Year Program | Australia | Aussizz Group"
                  >
                    Accounting
                  </Link>
                </ListItem>
                {/* <ListItem className="footer-navitem">
                  <Link
                    passHref
                    className="nav-item-link"
                    href="/naati-endorsed-courses"
                    title="NAATI Approved Courses - Professional Year Program | Australia | Aussizz Group"
                  >
                    NAATI Endorsed Courses
                  </Link>
                </ListItem> */}
              </List>
            </FooterPanel>
          </Grid>
          <Grid item lg={2.5} md={4} sm={6} xs={12}>
            <FooterPanel>
              <FooterTitle>Institutions</FooterTitle>
              <List className="footer-menu">
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/navitas-professional"
                    title="Navitas - Professional Year Program | Australia | Aussizz Group"
                  >
                    Navitas Professional
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/monash-professional-pathways"
                    title="Monash Professional Pathway - Professional Year Program | Australia | Aussizz Group"
                  >
                    Monash Professional Pathways
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/performance-education"
                    title="Performance Education - Professional Year Program | Australia | Aussizz Group"
                  >
                    Performance Education
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/education-centre-australia"
                    title="Education Centre of Australia (ECA) - Professional Year Program | Australia | Aussizz Group"
                  >
                    Education Centre of Australia
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/queensland-international-business-academy"
                    title="QIBA | IT - Professional Year Program | Australia | Aussizz Group"
                  >
                    Queensland International Business Academy
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/stanley-college"
                    title="Stanley College Perth | Accounting - Professional Year Program | Australia | Aussizz Group"
                  >
                    Stanley College
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/nit-australia"
                    title="NIT Australia - Professional Year Program | Australia | Aussizz Group"
                  >
                    NIT Australia
                  </Link>
                </ListItem>
              </List>
            </FooterPanel>
          </Grid>
          <Grid item lg={1.5} md={4} sm={6} xs={12}>
            <FooterPanel>
              <FooterTitle>Our Products</FooterTitle>
              <List className="footer-menu">
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href={PTE_URL}
                    target="_blank"
                    title="PTE Tutorials | Australia | Aussizz Group"
                  >
                    PTE Tutorials
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href={IELTS_URL}
                    target="_blank"
                    title="IELTS Tutorials | Australia | Aussizz Group"
                  >
                    IELTS Tutorials
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href={CCL_URL}
                    target="_blank"
                    title="CCL Tutorials | Australia | Aussizz Group"
                  >
                    CCL Tutorials
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href={OS_URL}
                    target="_blank"
                    title="Occusearch | Australia | Aussizz Group"
                  >
                    Occusearch
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href={GMP_URL}
                    target="_blank"
                    title="Get My Policy | Australia | Aussizz Group"
                  >
                    Get My Policy
                  </Link>
                </ListItem>
              </List>
            </FooterPanel>
          </Grid>
          <Grid item lg={1} md={4} sm={6} xs={12}>
            <FooterPanel>
              <FooterTitle>Others</FooterTitle>
              <List className="footer-menu">
                {/* <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/point-test"
                    title="Professional Year Program | IT, Accounting & Engineering | Australia | Aussizz Group"
                  >
                    Point Test
                  </Link>
                </ListItem> */}
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/blogs"
                    title="Blogs | Professional Year Program | Australia | Aussizz Group"
                  >
                    Blogs
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/faq"
                    title="FAQs | Professional Year Program | Australia | Aussizz Group"
                  >
                    FAQ
                  </Link>
                </ListItem>
                <ListItem className="footer-navitem">
                  <Link
                    prefetch={false}
                    passHref
                    className="nav-item-link"
                    href="/contact-us"
                    title="Contact Us | Professional Year Program | Australia | Aussizz Group"
                  >
                    Contact Us
                  </Link>
                </ListItem>
              </List>
            </FooterPanel>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FooterPanel>
              <FooterTitle>Address</FooterTitle>
              <List className="footer-menu">
                <ListItem className="footer-navitem address-navitem">
                  <AddressIcon />
                  <Typography className="address-text">
                    Level 1, Block Court, 288-290 Collins Street, Melbourne,
                    Victoria - 3000, Australia
                  </Typography>
                </ListItem>
                <ListItem className="footer-navitem address-navitem">
                  <PhoneIcon />
                  <Typography className="address-text">
                    <Link prefetch={false} className="nav-item-link" href="tel:+61-3-9602-3435">
                      +61-3-9602-3435
                    </Link>
                  </Typography>
                </ListItem>
                <ListItem className="footer-navitem address-navitem">
                  <MailOutlineIcon />
                  <Typography className="address-text">
                    <Link
                      prefetch={false}
                      className="nav-item-link"
                      href="mailto:info@pyaustralia.com.au"
                    >
                      info@pyaustralia.com.au
                    </Link>
                  </Typography>
                </ListItem>
              </List>
            </FooterPanel>
          </Grid>
          <Grid item sm={12} xs={12}>
            <CopyWriteSection>
              <Typography className="cw-text" variant="h3">
                © {new Date().getFullYear()}, Aussizz Group. All rights reserved.
              </Typography>
            </CopyWriteSection>
          </Grid>
        </Grid>
      </Container>
      <Box className="footer-effect">
        <Logo />
      </Box>
      <EnrollDialogForm onOpen={open} onClose={handleClose} />
    </FooterSection>
  );
};

export default Footer;
