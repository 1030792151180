import React, { useEffect } from "react";
import { HeaderSection, MobileMenu } from "./Header.styled";
import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  List,
  ListItem,
  Menu,
  MenuItem,
} from "@mui/material";
import { ChevronDownOutlineIcon, MenuOutlineIcon } from "ui/icons";
import { Logo } from "ui";
import Link from "next/link";
import { useRouter } from "next/router";
import GoogleTranslate from "../Common/GoogleTranslate";
import { CCL_URL, GMP_URL, IELTS_URL, OS_URL, PTE_URL } from "helpers/constant";
import { useAppContext } from "providers/DeviceProvider";
import Image from "next/image";

const Header = () => {
  const router = useRouter();
  const { isTablet } = useAppContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const ProgramsOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [institutionsEl, setInstitutionsEl] =
    React.useState<null | HTMLElement>(null);
  const InstitutionsOpen = Boolean(institutionsEl);
  const InstitutionsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setInstitutionsEl(event.currentTarget);
  };
  const InstitutionsClose = () => {
    setInstitutionsEl(null);
  };

  const [ProductEl, setProductEl] = React.useState<null | HTMLElement>(null);
  const ProductOpen = Boolean(ProductEl);
  const handleProductClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setProductEl(event.currentTarget);
  };
  const handleProductClose = () => {
    setProductEl(null);
  };
  const [headerClass, setHeaderClass] = React.useState("header-fixed");
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("mousewheel", isSticky);
    window.addEventListener("click", onDocumentClick);
    return () => {
      window.removeEventListener("mousewheel", isSticky);
      window.removeEventListener("click", onDocumentClick);
    };
  }, []);
  const onDocumentClick = (event: any) => {
    const menu = document.getElementById("header-menu");
    if (!menu?.contains(event?.target)) {
      setResponsiveOpen(false);
      setProgramOpen(false);
      setProductOpen(false);
      setInstitutionsOpen(false);
    }
  };
  /* Method that will fix header after a specific scrollable */
  const isSticky = (event: any) => {
    setResponsiveOpen(false);
    setProgramOpen(false);
    setProductOpen(false);
    setInstitutionsOpen(false);
    setProductEl(null);
    setInstitutionsEl(null);
    setAnchorEl(null);
    event.wheelDelta >= 0
      ? setHeaderClass("nav-down")
      : setHeaderClass("nav-up");
  };

  // Responsive Header
  const [responsiveopen, setResponsiveOpen] = React.useState(false);
  const responsiveClickH = () => {
    setResponsiveOpen(!responsiveopen);
  };

  // Responsive Header
  const [programopen, setProgramOpen] = React.useState(false);
  const programClick = () => {
    setProgramOpen(!programopen);
  };

  // Responsive Header
  const [productopen, setProductOpen] = React.useState(false);
  const productClick = () => {
    setProductOpen(!productopen);
  };

  // Responsive Header
  const [institutionsopen, setInstitutionsOpen] = React.useState(false);
  const institutionsClick = () => {
    setInstitutionsOpen(!institutionsopen);
  };

  const [isLoaded, setIsLoaded] = React.useState(false);

  useEffect(() => {
    if (router.isReady) {
      setIsLoaded(true);
    }
  }, [router.isReady]);

  return (
    // header-fixed
    <>
      {isLoaded && (
        <HeaderSection
          className={isTablet ? `header-fixed` : `header-fixed ${headerClass}`}
          id="header-menu"
        >
          <Container maxWidth="xl">
            <Grid container columnSpacing={3}>
              <Grid item xs={12}>
                <Box className="header-box">
                  <Link
                    prefetch={false}
                    passHref
                    className="header-logo"
                    href="/"
                    title="Professional Year Program | IT, Accounting & Engineering | Australia | Aussizz Group"
                  >
                    <Logo />
                    <Image
                      src={
                        "https://www.aussizzgroup.com/wp-content/themes/aussizzgroup/assets/images/logo.png"
                      }
                      width={100}
                      height={100}
                      alt={"aussizzgroup logo"}
                      className="aussizzgroup-logo"
                    />
                  </Link>

                  {!isTablet && (
                    <List className="nav-box">
                      <ListItem>
                        <Link
                          prefetch={false}
                          passHref
                          href={router.asPath}
                          title="Programs"
                          id="basic-button"
                          aria-controls={
                            ProgramsOpen ? "basic-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={ProgramsOpen ? "true" : undefined}
                          onClick={(e) => {
                            handleClick(e);
                            return false;
                          }}
                          className="nav-link"
                        >
                          Programs
                          <ChevronDownOutlineIcon />
                        </Link>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={ProgramsOpen}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          className="menu-item-box"
                        >
                          <MenuItem onClick={handleClose}>
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/it-program"
                              title="IT (Information Technology) - Professional Year Program | Australia | Aussizz Group"
                            >
                              IT Professional
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/engineering-program"
                              title="Engineering Professional - Professional Year Program | Australia | Aussizz Group"
                            >
                              Engineering
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/accounting-program"
                              title="Accounting Professional Year (PY) - Professional Year Program | Australia | Aussizz Group"
                            >
                              Accounting
                            </Link>
                          </MenuItem>
                          {/* <MenuItem onClick={handleClose}>
                            <Link
                              passHref
                              className="sub-nav-link"
                              href="/naati-endorsed-courses"
                              title="NAATI Approved Courses - Professional Year Program | Australia | Aussizz Group"
                            >
                              NAATI Endorsed Courses
                            </Link>
                          </MenuItem> */}
                        </Menu>
                      </ListItem>

                      <ListItem>
                        <Link
                          prefetch={false}
                          passHref
                          id="institutions-button"
                          aria-controls={
                            InstitutionsOpen ? "institutions-menu" : undefined
                          }
                          title="Institutions"
                          href={router.asPath}
                          aria-haspopup="true"
                          aria-expanded={InstitutionsOpen ? "true" : undefined}
                          onClick={(e) => {
                            InstitutionsClick(e);
                            return false;
                          }}
                          className="nav-link"
                        >
                          Institutions
                          <ChevronDownOutlineIcon />
                        </Link>
                        <Menu
                          id="institutions-menu"
                          anchorEl={institutionsEl}
                          open={InstitutionsOpen}
                          onClose={InstitutionsClose}
                          MenuListProps={{
                            "aria-labelledby": "institutions-button",
                          }}
                          className="menu-item-box"
                        >
                          <MenuItem onClick={InstitutionsClose}>
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/navitas-professional"
                              title="Navitas - Professional Year Program | Australia | Aussizz Group"
                            >
                              Navitas Professional
                            </Link>
                          </MenuItem>
                          <MenuItem
                            onClick={InstitutionsClose}
                            className="sub-nav-link"
                          >
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/monash-professional-pathways"
                              title="Monash Professional Pathway - Professional Year Program | Australia | Aussizz Group"
                            >
                              Monash Professional Pathways
                            </Link>
                          </MenuItem>
                          <MenuItem
                            onClick={InstitutionsClose}
                            className="sub-nav-link"
                          >
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/performance-education"
                              title="Performance Education - Professional Year Program | Australia | Aussizz Group"
                            >
                              Performance Education
                            </Link>
                          </MenuItem>
                          <MenuItem
                            onClick={InstitutionsClose}
                            className="sub-nav-link"
                          >
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/education-centre-australia"
                              title="Education Centre of Australia (ECA) - Professional Year Program | Australia | Aussizz Group"
                            >
                              Education Centre of Australia
                            </Link>
                          </MenuItem>
                          <MenuItem
                            onClick={InstitutionsClose}
                            className="sub-nav-link"
                          >
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/queensland-international-business-academy"
                              title="QIBA | IT - Professional Year Program | Australia | Aussizz Group"
                            >
                              Queensland International Business Academy
                            </Link>
                          </MenuItem>
                          <MenuItem
                            onClick={InstitutionsClose}
                            className="sub-nav-link"
                          >
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/stanley-college"
                              title="Stanley College Perth | Accounting - Professional Year Program | Australia | Aussizz Group"
                            >
                              Stanley College
                            </Link>
                          </MenuItem>
                          <MenuItem
                            onClick={InstitutionsClose}
                            className="sub-nav-link"
                          >
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href="/nit-australia"
                              title="NIT Australia - Professional Year Program | Australia | Aussizz Group"
                            >
                              NIT Australia
                            </Link>
                          </MenuItem>
                        </Menu>
                      </ListItem>

                      <ListItem>
                        <Link
                          prefetch={false}
                          passHref
                          href={router.asPath}
                          title="Our Products"
                          id="product-button"
                          aria-controls={
                            ProductOpen ? "product-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={ProductOpen ? "true" : undefined}
                          onClick={(e) => {
                            handleProductClick(e);
                            return false;
                          }}
                          className="nav-link"
                        >
                          Our Products
                          <ChevronDownOutlineIcon />
                        </Link>
                        <Menu
                          id="product-menu"
                          anchorEl={ProductEl}
                          open={ProductOpen}
                          onClose={handleProductClose}
                          MenuListProps={{
                            "aria-labelledby": "product-button",
                          }}
                          className="menu-item-box"
                        >
                          <MenuItem onClick={handleProductClose}>
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href={PTE_URL}
                              target="_blank"
                              title="PTE Tutorials | Australia | Aussizz Group"
                            >
                              PTE Tutorials
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleProductClose}>
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href={IELTS_URL}
                              target="_blank"
                              title="IELTS Tutorials | Australia | Aussizz Group"
                            >
                              IELTS Tutorials
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleProductClose}>
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href={CCL_URL}
                              target="_blank"
                              title="CCL Tutorials | Australia | Aussizz Group"
                            >
                              CCL Tutorials
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleProductClose}>
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href={OS_URL}
                              target="_blank"
                              title="Occusearch | Australia | Aussizz Group"
                            >
                              Occusearch
                            </Link>
                          </MenuItem>
                          <MenuItem onClick={handleProductClose}>
                            <Link
                              prefetch={false}
                              passHref
                              className="sub-nav-link"
                              href={GMP_URL}
                              target="_blank"
                              title="Get My Policy | Australia | Aussizz Group"
                            >
                              Get My Policy
                            </Link>
                          </MenuItem>
                        </Menu>
                      </ListItem>

                      {/* <ListItem>
                        <Link
                          prefetch={false}
                          passHref
                          className="nav-link"
                          href="/point-test"
                          title="Professional Year Program | IT, Accounting & Engineering | Australia | Aussizz Group"
                        >
                          Point Test
                        </Link>
                      </ListItem> */}
                      <ListItem>
                        <Link
                          prefetch={false}
                          passHref
                          className="nav-link"
                          href="/blogs"
                          title="Blogs | Professional Year Program | Australia | Aussizz Group"
                        >
                          Blogs
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          prefetch={false}
                          passHref
                          className="nav-link"
                          href="/faq"
                          title="FAQs | Professional Year Program | Australia | Aussizz Group"
                        >
                          FAQ
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          prefetch={false}
                          passHref
                          className="nav-link"
                          href="/contact-us"
                          title="Contact Us | Professional Year Program | Australia | Aussizz Group"
                        >
                          Contact Us
                        </Link>
                      </ListItem>
                      <ListItem>
                        <GoogleTranslate />
                      </ListItem>
                    </List>
                  )}

                  {/* Responsive Menu */}
                  {isTablet && (
                    <Button
                      className="responsive-btn"
                      aria-label="responsive-toggle"
                      onClick={responsiveClickH}
                    >
                      <MenuOutlineIcon />
                    </Button>
                  )}
                  {isTablet && (
                    <MobileMenu>
                      <Collapse
                        in={responsiveopen}
                        timeout="auto"
                        unmountOnExit
                        mountOnEnter
                      >
                        <List className="nav-box">
                          <ListItem>
                            <Button onClick={programClick} className="nav-link">
                              Programs
                              <ChevronDownOutlineIcon />
                            </Button>
                            <Collapse
                              in={programopen}
                              timeout="auto"
                              unmountOnExit
                              mountOnEnter
                            >
                              <List className="res-menu-box">
                                <ListItem
                                  onClick={() => {
                                    programClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/it-program"
                                    title="IT (Information Technology) - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    IT Professional
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    programClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/engineering-program"
                                    title="Engineering Professional - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    Engineering
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    programClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/accounting-program"
                                    title="Accounting Professional Year (PY) - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    Accounting
                                  </Link>
                                </ListItem>
                              </List>
                            </Collapse>
                          </ListItem>

                          <ListItem>
                            <Button
                              onClick={institutionsClick}
                              className="nav-link"
                              color="primary"
                            >
                              Institutions
                              <ChevronDownOutlineIcon />
                            </Button>
                            <Collapse
                              in={institutionsopen}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List className="res-menu-box">
                                <ListItem
                                  onClick={() => {
                                    institutionsClick();
                                    responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/navitas-professional"
                                    title="Navitas - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    Navitas Professional
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    institutionsClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/monash-professional-pathways"
                                    title="Monash Professional Pathway - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    Monash Professional Pathways
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    institutionsClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/performance-education"
                                    title="Performance Education - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    Performance Education
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    institutionsClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/education-centre-australia"
                                    title="Education Centre of Australia (ECA) - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    Education Centre of Australia
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    institutionsClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/queensland-international-business-academy"
                                    title="QIBA | IT - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    Queensland International Business Academy
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    institutionsClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/stanley-college"
                                    title="Stanley College Perth | Accounting - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    Stanley College
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    institutionsClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href="/nit-australia"
                                    title="NIT Australia - Professional Year Program | Australia | Aussizz Group"
                                  >
                                    NIT Australia
                                  </Link>
                                </ListItem>
                              </List>
                            </Collapse>
                          </ListItem>

                          <ListItem>
                            <Button onClick={productClick} className="nav-link">
                              Our Products
                              <ChevronDownOutlineIcon />
                            </Button>
                            <Collapse
                              in={productopen}
                              timeout="auto"
                              unmountOnExit
                              mountOnEnter
                            >
                              <List className="res-menu-box">
                                <ListItem
                                  onClick={() => {
                                    productClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href={PTE_URL}
                                    target="_blank"
                                    title="PTE Tutorials | Australia | Aussizz Group"
                                  >
                                    PTE Tutorials
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    productClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href={IELTS_URL}
                                    target="_blank"
                                    title="IELTS Tutorials | Australia | Aussizz Group"
                                  >
                                    IELTS Tutorials
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    productClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href={CCL_URL}
                                    target="_blank"
                                    title="CCL Tutorials | Australia | Aussizz Group"
                                  >
                                    CCL Tutorials
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    productClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href={OS_URL}
                                    target="_blank"
                                    title="Occusearch | Australia | Aussizz Group"
                                  >
                                    Occusearch
                                  </Link>
                                </ListItem>
                                <ListItem
                                  onClick={() => {
                                    productClick(), responsiveClickH();
                                  }}
                                >
                                  <Link
                                    prefetch={false}
                                    passHref
                                    className="sub-nav-link"
                                    href={GMP_URL}
                                    target="_blank"
                                    title="Get My Policy | Australia | Aussizz Group"
                                  >
                                    Get My Policy
                                  </Link>
                                </ListItem>
                              </List>
                            </Collapse>
                          </ListItem>

                          {/* <ListItem>
                            <Link
                              prefetch={false}
                              onClick={() => {
                                responsiveClickH();
                              }}
                              passHref
                              className="nav-link"
                              href="/point-test"
                              title="Professional Year Program | IT, Accounting & Engineering | Australia | Aussizz Group"
                            >
                              Point Test
                            </Link>
                          </ListItem> */}
                          <ListItem>
                            <Link
                              prefetch={false}
                              passHref
                              className="nav-link"
                              href="/blogs"
                              title="Blogs | Professional Year Program | Australia | Aussizz Group"
                              onClick={() => {
                                responsiveClickH();
                              }}
                            >
                              Blogs
                            </Link>
                          </ListItem>
                          <ListItem>
                            <Link
                              prefetch={false}
                              onClick={() => {
                                responsiveClickH();
                              }}
                              passHref
                              className="nav-link"
                              href="/faq"
                              title="FAQs | Professional Year Program | Australia | Aussizz Group"
                            >
                              FAQ
                            </Link>
                          </ListItem>
                          <ListItem>
                            <Link
                              prefetch={false}
                              onClick={() => {
                                responsiveClickH();
                              }}
                              passHref
                              className="nav-link"
                              href="/contact-us"
                              title="Contact Us | Professional Year Program | Australia | Aussizz Group"
                            >
                              Contact Us
                            </Link>
                          </ListItem>
                          <ListItem>
                            <GoogleTranslate />
                          </ListItem>
                        </List>
                      </Collapse>
                    </MobileMenu>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </HeaderSection>
      )}
    </>
  );
};

export default Header;
