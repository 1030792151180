import { useEffect } from "react";

const GoogleTranslate = () => {
    useEffect(() => {
        if (!document.getElementById('gtranslate') && document.getElementById('google_translate_element')) {
            const addScript = document.createElement('script');
            addScript.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
            addScript.id = 'gtranslate'
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;
        }
        return () => {
            const scriptData = document.getElementById('gtranslate');
            if (scriptData)
                scriptData.remove()

        }
    }, [])


    const googleTranslateElementInit = () => {
        try {


            if (window.google) {
                new window.google.translate.TranslateElement({
                    pageLanguage: 'auto',
                    autoDisplay: false,
                    // includedLanguages: "ru,en,pl", // If you remove it, by default all google supported language will be included
                    layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL
                },
                    'google_translate_element');
                const ddata = document.querySelectorAll('.goog-te-combo');
                if (ddata.length > 1)
                    ddata.forEach((element, index) => {
                        if (index > 0)
                            element.remove();
                    });

            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div id="google_translate_element"></div>
        </>
    )
}

export default GoogleTranslate