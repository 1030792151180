export const AvailableBranches = [
  {
    value: "",
    label: "All Cities",
  },
  {
    value: "Adelaide",
    label: "Adelaide",
  },
  {
    value: "Brisbane",
    label: "Brisbane",
  },
  {
    value: "Canberra",
    label: "Canberra",
  },
  {
    value: "Darwin",
    label: "Darwin",
  },
  {
    value: "Hobart",
    label: "Hobart",
  },
  {
    value: "Melbourne",
    label: "Melbourne",
  },
  {
    value: "Perth",
    label: "Perth",
  },
  {
    value: "Sydney",
    label: "Sydney",
  },
  {
    value: "Geelong",
    label: "Geelong",
  },
  {
    value: "Gold Coast",
    label: "Gold Coast",
  },
];

export const AvailablePrograms = [
  {
    value: "",
    label: "All Programs",
  },
  {
    value: "Professional Year in Accounting",
    label: "Professional Year in Accounting",
  },
  {
    value: "Professional Year in Engineering",
    label: "Professional Year in Engineering",
  },
  {
    value: "Professional Year in IT",
    label: "Professional Year in IT",
  },
];

export const AvailableInstitutes = [
  {
    value: "",
    label: "All Institutes",
  },
  {
    value: "Navitas Professional",
    label: "Navitas Professional",
  },
  {
    value: "Monash Professional Pathways",
    label: "Monash Professional Pathways",
  },
  {
    value: "Performance Education",
    label: "Performance Education",
  },
  {
    value: "Education Centre of Australia",
    label: "Education Centre of Australia",
  },
  {
    value: "Queensland International Business Academy",
    label: "Queensland International Business Academy",
  },
  {
    value: "Stanley College",
    label: "Stanley College",
  },
  {
    value: "NIT Australia",
    label: "NIT Australia",
  },
];

export const AvailablePriceRange = [
  {
    value: "",
    label: "All Price",
  },
  {
    value: "lessthan5000",
    label: "Less Than 5000",
  },
  {
    value: "5000to10000",
    label: "5000 - 10000",
  },
  {
    value: "10000to20000",
    label: "10000 - 20000",
  },
  {
    value: "20000to50000",
    label: "50000 - 50000",
  },
  {
    value: "50000to100000",
    label: "50000 - 100000",
  },
  {
    value: "morethan100000",
    label: "More Than 100000",
  },
];

export const AvailableService = [
  {
    value: "",
    label: "Select Service",
  },
  {
    value: "Migration",
    label: "Migration",
  },
  {
    value: "Education",
    label: "Education",
  },
  {
    value: "PTE",
    label: "PTE",
  },
  {
    value: "IELTS",
    label: "IELTS",
  },
  {
    value: "Professional Year",
    label: "PYA",
  },
  {
    value: "CCL",
    label: "CCL",
  },
];

export const AvailableCity = [
  {
    value: "",
    label: "Select Branch",
  },
  {
    value: "3",
    label: "Melbourne",
  },
  {
    value: "18",
    label: "Clayton",
  },
  {
    value: "37",
    label: "Elizabeth Street",
  },
  {
    value: "35",
    label: "Thomastown",
  },
  {
    value: "38",
    label: "Werribee",
  },
  {
    value: "11446",
    label: "Hawthorn",
  },
  {
    value: "11649",
    label: "Dandenong",
  },
  {
    value: "9",
    label: "Sydney",
  },
  {
    value: "32",
    label: "Parramatta",
  },
  {
    value: "11636",
    label: "Bankstown",
  },
  {
    value: "21",
    label: "Brisbane",
  },
  {
    value: "11520",
    label: "Gold Coast",
  },
  {
    value: "11436",
    label: "Mount Gravatt",
  },
  {
    value: "20",
    label: "Adelaide",
  },
  {
    value: "11727",
    label: "Truganina",
  },
  {
    value: "11414",
    label: "Mawson Lakes",
  },
  {
    value: "2",
    label: "Perth",
  },
  {
    value: "11410",
    label: "Northbridge",
  },
  {
    value: "11416",
    label: "Canningvale",
  },
  {
    value: "11597",
    label: "Canberra",
  },
  {
    value: "12226",
    label: "Footscray",
  },
];

export const PLACE_HOLDER_IMAGE = "/assets/images/imageloader.webp";

export const PAGE_ROUTES = [
  {
    url: "it-program",
    name: "IT Program",
    isProgram: true,
    isInstitute: false
  },
  {
    url: "engineering-program",
    name: "Engineering Program",
    isProgram: true,
    isInstitute: false
  },
  {
    url: "accounting-program",
    name: "Accounting Program",
    isProgram: true,
    isInstitute: false
  },
  {
    url: "naati-endorsed-courses",
    name: "NAATI Endorsed Courses",
    isProgram: true,
    isInstitute: false
  },
  {
    url: "navitas-professional",
    name: "Navitas Professional",
    isProgram: false,
    isInstitute: true
  },
  {
    url: "monash-professional-pathways",
    name: "Monash Professional Pathways",
    isProgram: false,
    isInstitute: true
  },
  {
    url: "performance-education",
    name: "Performance Education",
    isProgram: false,
    isInstitute: true
  },
  {
    url: "education-centre-australia",
    name: "Education Centre Australia",
    isProgram: false,
    isInstitute: true
  },
  {
    url: "queensland-international-business-academy",
    name: "Queensland International Business Academy",
    isProgram: false,
    isInstitute: true
  },
  {
    url: "stanley-college",
    name: "Stanley College",
    isProgram: false,
    isInstitute: true
  },
  {
    url: "nit-australia",
    name: "NIT Australia",
    isProgram: false,
    isInstitute: true
  },
  {
    url: "point-test",
    name: "Point Test",
    isProgram: false,
    isInstitute: false
  },
  {
    url: "faq",
    name: "Faq",
    isProgram: false,
    isInstitute: false
  },
  {
    url: "contact-us",
    name: "Contact Us",
    isProgram: false,
    isInstitute: false
  },
  {
    url: "blogs",
    name: "Blogs",
    isProgram: false,
    isInstitute: false
  },
];

export const WEB_URL = process.env.NEXT_PUBLIC_WEB_URL || "";
export const PTE_URL = process.env.NEXT_PUBLIC_PTE || "";
export const IELTS_URL = process.env.NEXT_PUBLIC_IELTS || "";
export const CCL_URL = process.env.NEXT_PUBLIC_CCL || "";
export const OS_URL = process.env.NEXT_PUBLIC_OS || "";
export const GMP_URL = process.env.NEXT_PUBLIC_GMP || "";
